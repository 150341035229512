import { gql } from "@apollo/client";

// GET THE TABLE LIST LOCATION

const GET_ALL_NOTIFICATIONS = gql`
    query GetAllNotificationsBasedOnIdQuery($userId: Int!){
        getAllNotificationsBasedOnId( userId: $userId){ 
            id
            name
            description
            type
            createdAt
        }
    }
`;

const NEW_NOTIFICATION = gql`
    subscription onNewNotificationSubscription {
        newNotification {
            id
            name
            description
            userId
            type
            createdAt
        }
    }
`;

export const notification = {
    query: {
        GET_ALL_NOTIFICATIONS
    },
    mutation: {
    },
    subscription: {
        NEW_NOTIFICATION
    }
}