/* eslint-disable */
import { gql } from "@apollo/client";

const CREATE_VEHICLE = gql`
  mutation createVehicleMutation($data: VehicleCreateInput!) {
    createVehicle(data: $data) {
      name
      plate
      status
      owner {
        profile {
          firstName
        }
      }
      ROV
      ITP
      ASIG
    }
  }
`;

const SEARCH_VEHICLE = gql`
  query onVehicleSearch(
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    vehicles(
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      vehicle {
        id
        name
        plate
        status
        owner {
          profile {
            firstName
          }
        }
        ROV
        ITP
        ASIG
      }
      count
    }
  }
`;

const GET_VEHICLE_BY_ID = gql`
  query GetVehicleById($id: String!) {
    getVehicleById(id: $id) {
      name
      plate
      status
      inventoryId
      owner {
        id
        profile {
          firstName
          lastName
        }
      }
      ROV
      ITP
      ASIG
      company {
        name
        inventoryId
      }
    }
  }
`;
const GET_ALL_VEHICLES = gql`
  query GetVehicleById {
    allVehicles {
      id
      name
      plate
      status
      inventoryId
      owner {
        id
        profile {
          firstName
          lastName
        }
        ownerForVehicles {
          name
          plate
        }
      }
      ROV
      ITP
      ASIG
      company {
        name
        inventoryId
      }
    }
  }
`;

const UPDATE_VEHICLE = gql`
  mutation updateVehicleMutation($data: VehicleCreateInput!) {
    updateVehicle(data: $data) {
      id
    }
  }
`;

const RETURN_TO_INVENTORY = gql`
  mutation returnToInventoryMutation(
    $inventoryId: String!
    $materialId: String!
    $ammount: Float!
    $totalAmmount: Float!
    $companyInventoryId: String!
  ) {
    returnToInventory(
      inventoryId: $inventoryId
      materialId: $materialId
      ammount: $ammount
      totalAmmount: $totalAmmount
      companyInventoryId: $companyInventoryId
    ) {
      id
    }
  }
`;
const MOVE_TO_INVENTORY = gql`
  mutation moveToInventoryMutation(
    $inventoryId: String!
    $materialId: String!
    $ammount: Float!
    $totalAmmount: Float!
    $companyForTransfer: String!
  ) {
    moveMaterial(
      inventoryId: $inventoryId
      materialId: $materialId
      ammount: $ammount
      totalAmmount: $totalAmmount
      companyForTransfer: $companyForTransfer
    ) {
      id
    }
  }
`;
export const vehicle = {
  query: {
    SEARCH_VEHICLE,
    GET_VEHICLE_BY_ID,
    GET_ALL_VEHICLES,
  },
  mutation: {
    CREATE_VEHICLE,
    UPDATE_VEHICLE,
    RETURN_TO_INVENTORY,
    MOVE_TO_INVENTORY,
  },
  subscription: {},
};
