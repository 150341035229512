import { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import AuthGuard from "./components/AuthGuard";
import DashboardLayout from "./Views/Dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";

const Loadable = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// DASHBOARD / HOME PAGE

const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const GeneralSettings = Loadable(lazy(() => import("./pages/GeneralSettings")));

// AUTHENTICATION / LOGIN PAGE

const Login = Loadable(lazy(() => import("./Views/Login/Login")));

// ACCOUNTS PAGES

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Version = Loadable(lazy(() => import("./pages/dashboard/Version")));

// CLIENTS PAGES

const ProspectsList = Loadable(
  lazy(() => import("./Views/prospects/ProspectsList"))
);
const ClientsAccountsDetails = Loadable(
  lazy(() => import("./pages/dashboard/ClientsAccountsDetails"))
);
const ClientsAccounts = Loadable(
  lazy(() => import("./Views/clientsAccounts/ClientsAccounts"))
);

// EMPLOYEE PAGES

const EmployeeAccountsEdit = Loadable(
  lazy(() => import("./pages/dashboard/EmployeeAccountsEdit"))
);
const EmployeeAccountsAdd = Loadable(
  lazy(() => import("./pages/dashboard/EmployeeAccountsAdd"))
);
const EmployeeAccountsDetails = Loadable(
  lazy(() => import("./pages/dashboard/EmployeeAccountsDetails"))
);
const EmployeeAccounts = Loadable(
  lazy(() => import("./Views/employeeAccounts/EmployeeAccounts"))
);

// CLIENT / CUSTOMER PAGE

const CustomerDetails = Loadable(
  lazy(() => import("./pages/dashboard/CustomerDetails"))
);
const CustomerEdit = Loadable(
  lazy(() => import("./pages/dashboard/CustomerEdit"))
);
const CustomerAdd = Loadable(
  lazy(() => import("./pages/dashboard/CustomerAdd"))
);
const CustomerList = Loadable(
  lazy(() => import("./Views/customers/CustomerList"))
);

// SERVICE PAGE

const ServicesDetails = Loadable(
  lazy(() => import("./pages/dashboard/ServicesDetails"))
);
const ServicesEdit = Loadable(
  lazy(() => import("./pages/dashboard/ServicesEdit"))
);
const ServicesAdd = Loadable(
  lazy(() => import("./pages/dashboard/ServicesAdd"))
);
const Services = Loadable(lazy(() => import("./Views/services/Services")));

// OPERATION PAGE

const Operations = Loadable(
  lazy(() => import("./Views/operations/Operations"))
);
const OperationsDetails = Loadable(
  lazy(() => import("./pages/dashboard/OperationsDetails"))
);
const OperationsAdd = Loadable(
  lazy(() => import("./pages/dashboard/OperationsAdd"))
);
const OperationsEdit = Loadable(
  lazy(() => import("./pages/dashboard/OperationsEdit"))
);

// COMPANY PAGE

const CompaniesDetails = Loadable(
  lazy(() => import("./pages/dashboard/CompaniesDetails"))
);
const CompaniesEdit = Loadable(
  lazy(() => import("./pages/dashboard/CompaniesEdit"))
);
const CompaniesAdd = Loadable(
  lazy(() => import("./pages/dashboard/CompaniesAdd"))
);
const Companies = Loadable(lazy(() => import("./Views/companies/Companies")));

// VEHICLE PAGE

const Vehicles = Loadable(lazy(() => import("./Views/vehicles/Vehicles")));
const VehiclesDetails = Loadable(
  lazy(() => import("./pages/dashboard/VehiclesDetails"))
);
const VehiclesEdit = Loadable(
  lazy(() => import("./pages/dashboard/VehiclesEdit"))
);
const VehiclesAdd = Loadable(
  lazy(() => import("./pages/dashboard/VehiclesAdd"))
);

// INVENTORY PAGE

const InventoryDetails = Loadable(
  lazy(() => import("./pages/dashboard/InventoryDetails"))
);
const InventoryEdit = Loadable(
  lazy(() => import("./pages/dashboard/InventoryEdit"))
);
const InventoryAddQuantity = Loadable(
  lazy(() => import("./pages/dashboard/InventoryAddQuantity"))
);
const Inventory = Loadable(lazy(() => import("./Views/inventory/Inventory")));
const InventoryCreate = Loadable(
  lazy(() => import("./pages/dashboard/InventoryCreate"))
);
const InventoryAddMaterialQuantity = Loadable(
  lazy(() => import("./pages/dashboard/InventoryAddMaterialQuantity"))
);
const TemporaryInventoryDetails = Loadable(
  lazy(() => import("./pages/dashboard/TemporaryInventory"))
);
const TemporaryInventories = Loadable(
  lazy(() => import("./Views/temporaryInventories/TemporaryInventories"))
);
const TemporaryInventoryAdd = Loadable(
  lazy(() => import("./pages/dashboard/TemporaryInventoryAdd"))
);
const TemporaryInventoryEdit = Loadable(
  lazy(() => import("./pages/dashboard/TemporaryInventoryEdit"))
);
// PRODUCT / MATERIAL FOR INVENTORY PAGE

const ProductAddForm = Loadable(
  lazy(() => import("./pages/dashboard/InventoryCreate"))
);

// ORDER PAGE

const OrderList = Loadable(lazy(() => import("./Views/orders/OrderList")));
const HistoryOrders = Loadable(
  lazy(() => import("./Views/historyOrders/HistoryOrders"))
);
const OrdersAdd = Loadable(lazy(() => import("./pages/dashboard/OrdersAdd")));
const OrderDetails = Loadable(
  lazy(() => import("./pages/dashboard/OrderDetails"))
);
const OrderApprove = Loadable(
  lazy(() => import("./pages/dashboard/OrderApprove"))
);

// ORDER NCR FORM

const FormNCR = Loadable(lazy(() => import("./pages/dashboard/FormNCR")));
const NCRfinish = Loadable(lazy(() => import("./pages/dashboard/NcrFinish")));

// LOCATION PAGE

const LocationDetails = Loadable(
  lazy(() => import("./pages/dashboard/LocationDetails"))
);
const LocationEdit = Loadable(
  lazy(() => import("./pages/dashboard/LocationEdit"))
);
const LocationList = Loadable(
  lazy(() => import("./Views/locations/LocationsList"))
);

// REPORT PAGE

const ReportsDetails = Loadable(
  lazy(() => import("./pages/dashboard/ReportsDetails"))
);
const ReportList = Loadable(lazy(() => import("./Views/reports/ReportList")));

// ERROR PAGE

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// ROUTES PAGE

const routes: PartialRouteObject[] = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "/general-settings",
        element: <GeneralSettings />,
      },
      {
        path: "prospects",
        children: [
          {
            path: "/",
            element: <ProspectsList />,
          },
          {
            path: "/add-customers",
            element: <CustomerAdd />,
          },
          {
            path: ":customerId",
            element: <CustomerDetails />,
          },
          {
            path: ":customerId/edit",
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: "customers",
        children: [
          {
            path: "/",
            element: <CustomerList />,
          },
          {
            path: "/add-customers",
            element: <CustomerAdd />,
          },
          {
            path: "/location/:locationId",
            element: <LocationDetails />,
          },
          {
            path: "/location/:locationId/edit",
            element: <LocationEdit />,
          },
          {
            path: ":customerId",
            element: <CustomerDetails />,
          },
          {
            path: ":customerId/edit",
            element: <CustomerEdit />,
          },
        ],
      },
      {
        path: "locations",
        children: [
          {
            path: "/",
            element: <LocationList />,
          },
        ],
      },
      {
        path: "employee-accounts",
        children: [
          {
            path: "/",
            element: <EmployeeAccounts />,
          },
          {
            path: "/add-employee",
            element: <EmployeeAccountsAdd />,
          },
          {
            path: ":id",
            element: <EmployeeAccountsDetails />,
          },
          {
            path: ":id/edit",
            element: <EmployeeAccountsEdit />,
          },
        ],
      },
      {
        path: "clients-accounts",
        children: [
          {
            path: "/",
            element: <ClientsAccounts />,
          },
          {
            path: ":id",
            element: <ClientsAccountsDetails />,
          },
        ],
      },
      {
        path: "inventory",
        children: [
          {
            path: "/",
            element: <Inventory />,
          },
          {
            path: "new",
            element: <InventoryCreate />,
          },
          {
            path: "/add-inventory/:companyCategory",
            element: <ProductAddForm />,
          },
          {
            path: ":id",
            element: <InventoryDetails />,
          },
          {
            path: ":id/edit",
            element: <InventoryEdit />,
          },
          {
            path: ":id/addQuantity",
            element: <InventoryAddQuantity />,
          },
          {
            path: ":id/add-quantity",
            element: <InventoryAddMaterialQuantity />,
          },
        ],
      },
      {
        path: "orders",
        children: [
          {
            path: "/",
            element: <OrderList />,
          },
          {
            path: "/byType/:type",
            element: <OrderList />,
          },
          {
            path: "/add-orders",
            element: <OrdersAdd />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
          {
            path: ":orderId/approve",
            element: <OrderApprove />,
          },
          {
            path: ":orderId/NCRfinish",
            element: <NCRfinish toStatus={"finish"} />,
          },
          {
            path: ":orderId/NCRdelay",
            element: <NCRfinish toStatus={"delay"} />,
          },
          {
            path: "/NCR",
            element: <FormNCR />,
          },
        ],
      },
      {
        path: "companies",
        children: [
          {
            path: "/",
            element: <Companies />,
          },
          {
            path: "/add-companies",
            element: <CompaniesAdd />,
          },
          {
            path: ":id",
            element: <CompaniesDetails />,
          },
          {
            path: ":id/edit",
            element: <CompaniesEdit />,
          },
        ],
      },
      {
        path: "vehicles",
        children: [
          {
            path: "/",
            element: <Vehicles />,
          },
          {
            path: "/add-vehicles",
            element: <VehiclesAdd />,
          },
          {
            path: ":id",
            element: <VehiclesDetails />,
          },
          {
            path: ":id/edit",
            element: <VehiclesEdit />,
          },
        ],
      },
      {
        path: "temporary-inventories",
        children: [
          {
            path: "/",
            element: <TemporaryInventories />,
          },
          {
            path: "/add",
            element: <TemporaryInventoryAdd />,
          },
          {
            path: ":id",
            element: <TemporaryInventoryDetails />,
          },
          {
            path: ":id/edit",
            element: <TemporaryInventoryEdit />,
          },
        ],
      },
      {
        path: "operations",
        children: [
          {
            path: "/",
            element: <Operations />,
          },
          {
            path: "/add-operations",
            element: <OperationsAdd />,
          },
          {
            path: ":id",
            element: <OperationsDetails />,
          },
          {
            path: ":id/edit",
            element: <OperationsEdit />,
          },
        ],
      },
      {
        path: "services",
        children: [
          {
            path: "/",
            element: <Services />,
          },
          {
            path: "/add-services",
            element: <ServicesAdd />,
          },
          {
            path: ":id",
            element: <ServicesDetails />,
          },
          {
            path: ":id/edit",
            element: <ServicesEdit />,
          },
        ],
      },
      {
        path: "history-orders",
        children: [
          {
            path: "/",
            element: <HistoryOrders />,
          },
        ],
      },
      {
        path: "reports",
        children: [
          {
            path: "/",
            element: <ReportList />,
          },
          {
            path: ":orderId",
            element: <ReportsDetails />,
          },
        ],
      },
      {
        path: "profile",
        children: [
          {
            path: "/",
            element: <Account />,
          },
        ],
      },
      {
        path: "version",
        children: [
          {
            path: "/",
            element: <Version />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
