import { useRef, useState, useEffect, useCallback } from "react";
import type { ElementType, FC } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import requests from "../../requests";
import { useSubscription } from "@apollo/client";
import BellIcon from "../../icons/Bell";
import ClipboardIcon from "../../icons/Clipboard";

const iconsMap: Record<string, ElementType> = {
  orderCreated: ClipboardIcon,
};

const NotificationsPopover: FC = () => {
  let storage = localStorage.getItem("userNotification");
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const [refreshPage, setRefreshPage] = useState<number>(0); // update the page with the new notification when pushed into the list " notificationStorage "
  const [open, setOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState([]);

  // GET Notification subscription when published
  const { data } = useSubscription(
    requests.notification.subscription.NEW_NOTIFICATION,
    { fetchPolicy: "network-only" }
  );

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const removeNotificationHandle = useCallback(
    (notificationId) => {
      let placeholder = [...notifications];
      setNotifications(
        placeholder.filter((item) => item.id !== notificationId)
      );
      localStorage.setItem(
        "userNotification",
        JSON.stringify(placeholder.filter((item) => item.id !== notificationId))
      );
    },
    [notifications]
  );

  useEffect(() => {
    if (data && data.newNotification) {
      notifications.push(data.newNotification);
      localStorage.setItem("userNotification", JSON.stringify(notifications));
      setRefreshPage(1);
    }
  }, [data, notifications]);

  // refreshPage when notification is pushed , update the page with the new notification

  useEffect(() => {
    if (typeof storage === "string") {
      let notificationStorage = JSON.parse(storage);
      setNotifications(notificationStorage);
    }
  }, [data, storage, refreshPage]);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={anchorRef} onClick={handleOpen}>
          <Badge color="error" badgeContent={notifications.length}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Notificări
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color="textSecondary" variant="subtitle2">
              Nu aveți notificări.
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.slice(0, 4).map((notification) => {
                const Icon = iconsMap["orderCreated"];

                return (
                  <ListItem
                    divider
                    key={notification.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => removeNotificationHandle(notification.id)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: "primary.main",
                          color: "primary.contrastText",
                        }}
                      >
                        <Icon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          color="textPrimary"
                          sx={{ cursor: "pointer" }}
                          underline="none"
                          variant="subtitle2"
                        >
                          {notification.name}
                        </Link>
                      }
                      secondary={notification.description}
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={() => {
                  setNotifications([]);
                  localStorage.setItem("userNotification", JSON.stringify([]));
                }}
              >
                Marchează totul ca fiind citit
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
