import { user } from "./user";
import { client } from "./client";
import { general } from "./general";
import { company } from "./company";
import { serviceType } from "./service";
import { operation } from "./operation";
import { vehicle } from "./vehicle";
import { inventory } from "./inventory";
import { location } from "./location";
import { order } from "./order";
import { notification } from "./notification";
import { temporaryInventory } from "./temporaryInventory";
/* eslint-disable */
const requests = {
  user,
  client,
  company,
  general,
  serviceType,
  operation,
  vehicle,
  inventory,
  location,
  order,
  notification,
  temporaryInventory,
};

export default requests;
