import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Drawer, Hidden } from '@material-ui/core';
import Logo from './Logo';

interface MainSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const MainSidebar: FC<MainSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;

  return (
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            width: 256
          }
        }}
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default MainSidebar;
