/* eslint-disable */
import { gql } from "@apollo/client";

//  GET ALL INVENTORIES

const GET_INVENTORY = gql`
  query GetInventoryQuery {
    getInventory {
      id
      materials {
        amount
        material {
          name
          um
          code
          materialClass
        }
      }
    }
  }
`;

// CREATE INVENTORY IN VEHICLE OR COMPANY

const CREATE_INVENTORY = gql`
  mutation createInventoryMutation(
    $userId: Int!
    $inventoryId: String!
    $amount: Float!
    $material: MaterialCreatInput!
  ) {
    createInventory(
      userId: $userId
      inventoryId: $inventoryId
      amount: $amount
      material: $material
    ) {
      amount
      inventoryId
      material {
        materialClass
        code
        name
        um
      }
    }
  }
`;

// CREATE MATERIAL / PRODUCT ON VEHICLE BASED ON ( MATERIAL ID , VEHICLE ID )

const CREATE_MATERIAL_VEHICLE = gql`
  mutation createMaterialOnVehicleMutation(
    $plateHistory: String!
    $nameHistory: String!
    $companyMaterialId: String!
    $companyMaterialAmount: Float!
    $inventoryId: String!
    $amount: Float!
    $materialId: String!
    $companyInventoryId: String!
    $userId: Int!
  ) {
    createMaterialOnVehicle(
      plateHistory: $plateHistory
      nameHistory: $nameHistory
      userId: $userId
      companyMaterialId: $companyMaterialId
      companyMaterialAmount: $companyMaterialAmount
      inventoryId: $inventoryId
      amount: $amount
      materialId: $materialId
      companyInventoryId: $companyInventoryId
    ) {
      companyMaterialAmount
      companyMaterialId
      companyInventoryId
      amount
      inventoryId
      materialId
    }
  }
`;

const CREATE_MATERIAL_TEMPORARY_INVENTORY = gql`
  mutation createMaterialOnTemporaryInventoryMutation(
    $nameHistory: String!
    $companyMaterialId: String!
    $companyMaterialAmount: Float!
    $inventoryId: String!
    $amount: Float!
    $materialId: String!
    $companyInventoryId: String!
    $userId: Int!
  ) {
    createMaterialOnTemporaryInventory(
      nameHistory: $nameHistory
      userId: $userId
      companyMaterialId: $companyMaterialId
      companyMaterialAmount: $companyMaterialAmount
      inventoryId: $inventoryId
      amount: $amount
      materialId: $materialId
      companyInventoryId: $companyInventoryId
    ) {
      companyMaterialAmount
      companyMaterialId
      companyInventoryId
      amount
      inventoryId
      materialId
    }
  }
`;

// ADD MORE MATERIALS ON COMPANY INVENTORY

const ADD_MORE_MATERIAL_TO_INVENTORY = gql`
  mutation addMoreMaterialsInInventoryMutation(
    $id: String!
    $userId: Int!
    $amount: Float!
  ) {
    addMoreMaterialsInInventory(id: $id, userId: $userId, amount: $amount) {
      amount
      inventoryId
      material {
        materialClass
        code
        name
        um
      }
    }
  }
`;

// GET THE PRODUCT / MATERIAL BASED ON ID

const GET_MATERIAL_BY_ID = gql`
  query getMaterialById($id: String!) {
    getMaterialById(id: $id) {
      id
      amount
      inventoryId
      material {
        id
        name
        um
        code
        materialClass
        price {
          price
        }
      }
    }
  }
`;

// GET THE INVENTORY AND MATERIALS/PRODUCTS IN IT BASED ON ID

const GET_INVENTORY_BY_ID = gql`
  query GetInventoryById($id: String!, $nonZero: Boolean) {
    getInventoryById(id: $id, nonZero: $nonZero) {
      vehicle {
        id
        name
      }
      materials {
        id
        amount
        material {
          id
          name
          um
          code
          materialClass
          price {
            price
          }
        }
      }
    }
  }
`;

// GET THE MATERIAL LIST FROM INVENTORY BASED ON COMPANY ID

const SEARCH_INVENTORY_FROM_COMPANY = gql`
  query GetInventoryFromCompanySearch(
    $inventoryId: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    getInventoryFromCompany(
      inventoryId: $inventoryId
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      materials {
        id
        amount
        inventory {
          company {
            id
          }
          vehicle {
            id
          }
        }
        material {
          name
          um
          materialClass
          code
          price {
            price
          }
        }
      }
      count
    }
  }
`;

// GET HISTORY INVENTORY ACTIONS BASED ON VEHICLE ID

const SEARCH_INVENTORY_ACTIONS_FOR_VEHICLE = gql`
  query GetAllHistoryMaterialFromVehicleSearch(
    $inventoryId: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    getAllHistoryMaterialFromVehicle(
      inventoryId: $inventoryId
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      materialInventoryActions {
        id
        type
        amount
        inventory {
          company {
            id
          }
          vehicle {
            id
          }
        }
        material {
          name
          um
          materialClass
          code
        }
        commentAction
        user {
          profile {
            firstName
            lastName
          }
        }
        updatedAt
        createdAt
      }
      count
    }
  }
`;
const SEARCH_INVENTORY_ACTIONS_FOR_TEMPORARY_INVENTORY = gql`
  query GetAllHistoryMaterialFromTemporaryInventorySearch(
    $inventoryId: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    getAllHistoryMaterialFromTemporaryInventory(
      inventoryId: $inventoryId
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      materialInventoryActions {
        id
        type
        amount
        inventory {
          company {
            id
          }
          vehicle {
            id
          }
        }
        material {
          name
          um
          materialClass
          code
        }
        commentAction
        user {
          profile {
            firstName
            lastName
          }
        }
        updatedAt
        createdAt
      }
      count
    }
  }
`;

// UPDATE MATERIAL / PRODUCT

const UPDATE_MATERIAL = gql`
  mutation updateMaterialMutation(
    $authorId: Int!
    $id: String!
    $amount: Float!
    $material: MaterialCreatInput!
  ) {
    updateInventory(
      authorId: $authorId
      id: $id
      amount: $amount
      material: $material
    ) {
      id
    }
  }
`;

// RESET THE INVENTORY BASED ON VEHICLE ID ( RETURN ALL MATERIAL AMOUNT TO 0 )

const RESET_MATERIAL_ON_VEHICLE = gql`
  mutation ResetAllMaterialsOnVehicle(
    $nameHistory: String!
    $plateHistory: String!
    $userId: Int!
    $inventoryId: String!
    $companyMaterialId: String!
  ) {
    resetMaterialOnVehicle(
      nameHistory: $nameHistory
      plateHistory: $plateHistory
      userId: $userId
      inventoryId: $inventoryId
      companyMaterialId: $companyMaterialId
    ) {
      id
    }
  }
`;

// GENERATE INVENTORY EXCEL FILE

const IMPORT_INVENTORY_EXCEL_FILE = gql`
  mutation onImportInventoryExcelFile($inventoryId: String!) {
    importInventoryExcelFile(inventoryId: $inventoryId)
  }
`;

const EXPORT_TOTAL_INVENTORY_EXCEL_FILE = gql`
  mutation onImportInventoryExcelFile {
    exportInventoryExcel
  }
`;

// ADD PRODUCTS FORM CSV
const ADD_INVENTORY_FROM_CSV = gql`
  mutation AddInventoryFromCSV($data: MaterialsFromCSVInput!) {
    addInventoryFromCSV(data: $data) {
      message
    }
  }
`;

export const inventory = {
  query: {
    SEARCH_INVENTORY_ACTIONS_FOR_VEHICLE,
    SEARCH_INVENTORY_ACTIONS_FOR_TEMPORARY_INVENTORY,
    GET_INVENTORY_BY_ID,
    GET_INVENTORY,
    SEARCH_INVENTORY_FROM_COMPANY,
    GET_MATERIAL_BY_ID,
  },
  mutation: {
    ADD_MORE_MATERIAL_TO_INVENTORY,
    IMPORT_INVENTORY_EXCEL_FILE,
    RESET_MATERIAL_ON_VEHICLE,
    CREATE_MATERIAL_VEHICLE,
    CREATE_MATERIAL_TEMPORARY_INVENTORY,
    CREATE_INVENTORY,
    UPDATE_MATERIAL,
    ADD_INVENTORY_FROM_CSV,
    EXPORT_TOTAL_INVENTORY_EXCEL_FILE,
  },
  subscription: {},
};
