/* eslint-disable */
import { gql } from "@apollo/client";

// GET USER INDORMATION

const ME_QUERY = gql`
{
    me {
        id
        email
        active
        profile {
            id
            firstName
            lastName
        }
        customer{
            id
            companyId
        }
        role {
            id
            name
            value
        }
        createdAt
    }
}
`;

const SIGN_IN_QUERY = gql`
mutation SignInMutation($email: String!, $password: String!, $rememberMe: Boolean! = false) {
    signInUser(email: $email, password: $password, rememberMe: $rememberMe) {
        user {
            id
        }
        token,
    }
}
`;

const SEARCH_ROLES = gql`
    query onRolesSearch {
        roles {
            id
            name
            value
            permissions
            noOfPermissions
        }
    }
`;

const CREATE_ROLE = gql`
    mutation onRoleCreate($name: String, $permissions: [Boolean!]!) {
        createRole(name: $name, permissions: $permissions) {
            id
        }
    }
`;

const UPDATE_ROLE = gql`
    mutation onRoleUpdate($id: Int!,$name: String, $permissions: [Boolean]!) {
        updateRole(id: $id, name: $name, permissions: $permissions) {
            id
        }
    }
`;

const DELETE_ROLE = gql`
    mutation onRoleDelete($id: Int!) {
        deleteRole(id: $id) {
            id
        }
    }
`;

const GET_PERMISSION_LIST = gql`
{
    permissions {
        id
        key
        value
    }
}
`;

const CREATE_USER_EMPLOYEE = gql`
    mutation onCreateEmployeeAccount(
        $firstName: String!, 
        $lastName: String!, 
        $email: String!, 
        $roleId: Int!,
        $workPhone: String!,
        $personalPhone: String!,
        $active: Boolean!,

    ) {createEmployeeAccount(
        firstName: $firstName, 
        lastName: $lastName, 
        email: $email, 
        roleId: $roleId,
        active: $active,
        workPhone: $workPhone,
        personalPhone: $personalPhone,
        )
        {
            id,
            email,
            roleId,
            active,
            password
        }
    }
`;

const CREATE_USER_CLIENT = gql`
    mutation onCreateClientAccount(
        $customerId: String!,
        $firstName: String!, 
        $lastName: String!, 
        $roleId: Int!,
        $workPhone: String!,
        $personalPhone: String!,
        $active: Boolean!,

    ) {createClientAccount(
        customerId: $customerId,
        firstName: $firstName, 
        lastName: $lastName, 
        roleId: $roleId,
        active: $active,
        workPhone: $workPhone,
        personalPhone: $personalPhone,
        )
        {
            showEmail,
            showPassword,
        }
    }
`;

const SEARCH_USER_CLIENT_ACCOUNT = gql`
    query onClientAccountsSearch($page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        clientsAccounts(page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            clientsAccounts {
                id
                email
                role {
                    name
                }
                profile {
                    firstName
                    lastName
                    phoneNumbers {
                    name
                    phone
                    }
                }
                active
                lastActive
            }
            count
        }
    }
`;

const SEARCH_USER_EMPLOYEE = gql`
    query onEmployeeSearch($page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        employees(page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            employees {
                id
                email
                role {
                    name
                }
                profile {
                    firstName
                    lastName
                    phoneNumbers {
                    name
                    phone
                    }
                }
                active
                lastActive
            }
            count
        }
    }
`;

const GET_ROLES_ACCOUNTS = gql`
    query GetRolesAccounts {
        getUserRoles {
            id
            name
            value
        }
    }
`;

const GET_ALL_TECHNICIANS = gql`
    query GetAllTechniciansQuery {
        getAllTechnicians {
            id
            profile {
                firstName
                lastName
            }
            ownerForVehicles{
                id
                name
                plate
            }
        }
    }
`;

const GET_USER_BY_ID = gql`
    query GetUserById ($id: Int!) {
        getUserById (id: $id){
            id
                email
                role {
                    id
                    name
                    value
                }
                profile {
                    id
                    firstName
                    lastName
                    phoneNumbers {
                    id
                    name
                    phone
                    }
                }
                active
                lastActive
        }
    }
`;

const UPDATE_USER_EMPLOYEE = gql`
    mutation onUpdateEmployeeAccount(
        $firstName: String!, 
        $lastName: String!, 
        $email: String!, 
        $roleId: Int!,
        $workPhone: String!,
        $personalPhone: String!,
        $active: Boolean!,
        $workId: String!,
        $personalId: String!,
        $profileEntryId: String!,
        $emailBefore: String!,

    ) {updateEmployeeAccount(
        firstName: $firstName, 
        lastName: $lastName, 
        email: $email, 
        roleId: $roleId,
        active: $active,
        workPhone: $workPhone,
        personalPhone: $personalPhone,
        workId: $workId,
        personalId: $personalId,
        profileEntryId: $profileEntryId,
        emailBefore: $emailBefore,
        )
        {
            id
        }
    }
`;

const UPDATE_USER_CLIENT_STATUS = gql`
    mutation onUpdateClientStatusAccount(
        $id: Int!, 
        $active: Boolean!,

    ) {updateClientStatusAccount(
        id: $id,
        active: $active,
        )
        {
            active
        }
    }
`;

const CHANGE_PASSWORD_USER = gql`
mutation onChangePasswordUser(
    $id: Int!, 
    $oldPassword: String!,
    $password: String!,
) {changePasswordUser(
    id: $id,
    oldPassword: $oldPassword,
    password: $password,
    )
    {
        id
        password
    }
}
`;

const RESET_USER_PASSWORD_ACCOUNT = gql`
    mutation onResetUserPasswordAccount($id: Int!)
    {
        resetUserPasswordAccount(id: $id)
        {
            passwordGenerate
        }
    }
`;

export const user = {
    query: {
        SEARCH_USER_CLIENT_ACCOUNT,
        GET_ALL_TECHNICIANS,
        GET_USER_BY_ID,
        GET_ROLES_ACCOUNTS,
        SEARCH_USER_EMPLOYEE,
        ME_QUERY,
        SEARCH_ROLES,
        GET_PERMISSION_LIST
    },
    mutation: {
        RESET_USER_PASSWORD_ACCOUNT,
        CHANGE_PASSWORD_USER,
        UPDATE_USER_CLIENT_STATUS,
        CREATE_USER_CLIENT,
        UPDATE_USER_EMPLOYEE,
        CREATE_USER_EMPLOYEE,
        SIGN_IN_QUERY,
        CREATE_ROLE,
        UPDATE_ROLE,
        DELETE_ROLE,
    },
    subscription: {
    }
}
