import { createContext } from "react";
import { useLocalStorage } from "src/hooks/useLocalStorage";

export const PaginationContext = createContext({
  rowsPerPage: 10,
  setRowsPerPage: (_: number) => null,
});

export const PaginationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [rowsPerPage, setRowsPerPage] = useLocalStorage("rowsPerPage", 10);

  const value = {
    rowsPerPage,
    setRowsPerPage,
  };
  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};
