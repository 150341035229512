/* eslint-disable */
import { gql } from "@apollo/client";
//mutation
const CREATE_OPERATION = gql`
  mutation CreateOperationMutation($data: OperationCreateInput!) {
    createOperation(data: $data) {
      name
      unitMeasure
      price {
        price
      }
      serviceType {
        title
      }
      code
    }
  }
`;

const UPDATE_OPERATION = gql`
  mutation updateOperationMutation($data: OperationCreateInput!) {
    updateOperation(data: $data) {
      id
    }
  }
`;

// GET TABLE LIST OPERATION

const SEARCH_OPERATION = gql`
  query onOperationSearch(
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
    $serviceData: String!
  ) {
    operations(
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
      serviceData: $serviceData
    ) {
      operation {
        id
        name
        unitMeasure
        price {
          price
        }
        serviceType {
          title
        }
        code
      }
      count
    }
  }
`;

const GET_OPERATION_BY_ID = gql`
  query GetOperationById($id: String!) {
    getOperationById(id: $id) {
      name
      unitMeasure
      price {
        id
        price
      }
      serviceType {
        id
        title
        company {
          id
          name
        }
      }
      code
    }
  }
`;

// GET SERVICE BASED ON OPERATION ID

const GET_OPERATIONS_BY_SERVICE_TYPE = gql`
  query GetOperations($typeId: String!) {
    getOperations(typeId: $typeId) {
      id
      code
      name
      unitMeasure
      price {
        id
        price
      }
      serviceType {
        id
        title
        company {
          id
          name
        }
      }
    }
  }
`;
export const operation = {
  query: {
    GET_OPERATION_BY_ID,
    SEARCH_OPERATION,
    GET_OPERATIONS_BY_SERVICE_TYPE,
  },
  mutation: {
    CREATE_OPERATION,
    UPDATE_OPERATION,
  },
  subscription: {},
};
