/* eslint-disable */
import { gql } from "@apollo/client";
//mutation
const CREATE_ORDER = gql`
  mutation CreateOrderMutation($data: OrderInputType!) {
    createOrder(data: $data) {
      id
    }
  }
`;
const APPROVE_AND_ALLOCATE_ORDER = gql`
  mutation ApproveAndAllocateOrderMutation($data: OrderApproveInputType!) {
    approveOrder(data: $data) {
      id
    }
  }
`;
const ORDER_TO_PAID = gql`
  mutation OrderToPaidUpdate($orderId: String) {
    orderToPaid(orderId: $orderId) {
      id
    }
  }
`;
const ORDER_TO_CANCEL = gql`
  mutation OrderToCloseUpdate($orderId: String) {
    orderToCancel(orderId: $orderId) {
      id
    }
  }
`;

//query
const GET_CLOSED_ORDERS = gql`
  query ClosedOrders(
    $endData: String
    $startData: String
    $technicianData: Int!
    $serviceData: String!
    $locationData: String!
    $clientData: String!
    $companyData: String!
    $userId: Int!
    $userRole: Int!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    closedOrders(
      endData: $endData
      startData: $startData
      technicianData: $technicianData
      locationData: $locationData
      clientData: $clientData
      companyData: $companyData
      serviceData: $serviceData
      userId: $userId
      userRole: $userRole
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      count
      orders {
        id
        company {
          name
        }
        location {
          name
          customer {
            subscription
            business {
              name
            }
            residential {
              firstName
              lastName
            }
          }
        }
        serviceType {
          title
        }
        driverId
        driver {
          id
          profile {
            firstName
            lastName
          }
        }
        NCRs {
          operations {
            amount
            price {
              price
            }
            serviceOperation {
              code
              name
              unitMeasure
            }
          }
          inventoryAction {
            amount
            price
            material {
              name
              um
            }
          }
          additionalMaterial {
            name
            um
            amount
          }
          document {
            name
            value
          }
          startAction {
            createdAt
          }
        }
        technicianId
        technician {
          id
          roleId
          role {
            id
            name
            value
          }
        }
        authorId
        priority
        status
        billPaid
        createdAt
        updatedAt
      }
    }
  }
`;

const WORK_ON_ORDER = gql`
  mutation ApproveAndAllocateOrderMutation(
    $latitude: String!
    $longitude: String!
    $precision: String!
    $comment: String
    $orderId: String!
    $createNCR: Boolean!
  ) {
    workOnOrder(
      latitude: $latitude
      longitude: $longitude
      precision: $precision
      comment: $comment
      orderId: $orderId
      createNCR: $createNCR
    ) {
      id
    }
  }
`;
//query
const GET_ALL_ORDERS = gql`
  query GetAllOrders(
    $billData: Boolean
    $priorityData: String!
    $technicianData: Int
    $statusData: String!
    $subscriptionData: Boolean
    $serviceData: String!
    $locationData: String!
    $clientData: String!
    $companyData: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
    $userId: Int!
    $userRole: Int!
    $customerId: String!
  ) {
    getAllOrders(
      companyData: $companyData
      clientData: $clientData
      locationData: $locationData
      serviceData: $serviceData
      subscriptionData: $subscriptionData
      statusData: $statusData
      technicianData: $technicianData
      priorityData: $priorityData
      billData: $billData
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
      userId: $userId
      userRole: $userRole
      customerId: $customerId
    ) {
      count
      orders {
        id
        company {
          name
        }
        location {
          name
          customer {
            subscription
            business {
              name
            }
            residential {
              firstName
              lastName
            }
          }
        }
        serviceType {
          title
        }
        driver {
          profile {
            firstName
            lastName
          }
        }
        equipments {
          equipment {
            name
          }
        }
        note
        priority
        status
        billPaid
        createdAt
      }
    }
  }
`;

const GET_ORDERS_FOR_DASHBOARD = gql`
  query getOrdersForDashboardQuery($userId: Int!, $clientId: String) {
    getOrdersForDashboard(userId: $userId, clientId: $clientId) {
      count
      countOPEN
      countAPPROVED
      countALLOCATED
      countINPROGRESS
      countDELAYED
      countFINISHED
      countCLOSED
    }
  }
`;

const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: String!) {
    getOrderById(id: $id) {
      id
      company {
        name
      }
      location {
        name
        address {
          region {
            region
          }
          city {
            city
          }
          streetName
          streetNo
          address
        }
        contacts {
          firstName
          lastName
          contactPhone {
            phone
          }
        }
        customer {
          subscription
          business {
            name
          }
          residential {
            firstName
            lastName
          }
          phoneNumbers {
            phone
          }
        }
      }
      serviceType {
        id
        title
      }
      actions {
        from
        to
        author {
          profile {
            firstName
            lastName
          }
        }
        createdAt
        gps {
          latitude
          longitude
          accuracy
        }
      }
      equipments {
        description
        equipment {
          name
        }
      }
      vehicle {
        name
        inventoryId
      }
      temporaryInventory {
        name
        inventoryId
      }
      technician {
        profile {
          firstName
          lastName
        }
      }
      driver {
        profile {
          firstName
          lastName
        }
      }
      NCRs {
        startAction {
          createdAt
        }
        operations {
          id
          amount
          serviceOperation {
            id
            code
            name
            unitMeasure
            price {
              price
            }
          }
        }
        inventoryAction {
          id
          amount
          price
          material {
            id
            name
            code
            um
          }
        }
        additionalMaterial {
          name
          um
          amount
        }
        document {
          value
          name
          createdAt
        }
      }
      note
      priority
      status
      billPaid
      updatedAt
    }
  }
`;

const GET_ALL_ORDERS_BY_LOCATION = gql`
  query getAllOrdersByLocationQuery(
    $locationId: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    allOrdersByLocation(
      locationId: $locationId
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      orders {
        id
        location {
          customer {
            business {
              name
            }
            residential {
              firstName
              lastName
            }
          }
        }
        company {
          name
        }
        serviceType {
          title
        }
        status
        driver {
          profile {
            firstName
            lastName
          }
        }
        billPaid
        priority
        createdAt
      }
      count
    }
  }
`;

const GET_ALL_ORDERS_BY_CUSTOMER = gql`
  query getAllOrdersByLocationQuery(
    $customerId: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    allOrdersByCustomer(
      customerId: $customerId
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      orders {
        id
        location {
          customer {
            business {
              name
            }
            residential {
              firstName
              lastName
            }
          }
        }
        company {
          name
        }
        serviceType {
          title
        }
        status
        driver {
          profile {
            firstName
            lastName
          }
        }
        billPaid
        priority
        createdAt
      }
      count
    }
  }
`;

const GET_USER_ORDER_ACTIVITY_DASHBOARD = gql`
  query getAllOrderActions(
    $clientId: String
    $userId: Int!
    $page: Int!
    $rowsPer: Int!
    $order: String!
    $crit: String!
  ) {
    allOrderActions(
      clientId: $clientId
      userId: $userId
      page: $page
      rowsPer: $rowsPer
      order: $order
      crit: $crit
    ) {
      orderAction {
        from
        to
        order {
          location {
            name
          }
        }
        author {
          role {
            name
          }
          profile {
            firstName
            lastName
          }
        }
        createdAt
        updatedAt
      }
      count
    }
  }
`;

const GET_CLOSED_ORDERS_ON_MONTH = gql`
  query getClosedOrdersOnMonthQuery {
    getClosedOrdersOnMonth {
      countCLOSED
      count
    }
  }
`;

const CREATE_ORDER_CLOSE_OR_POSTPONE_NCR = gql`
  mutation finishOrder($data: finishOrderType!) {
    finishOrder(data: $data) {
      id
    }
  }
`;

const CLOSE_ORDER = gql`
  mutation closeOrder($orderId: String!) {
    closeOrder(orderId: $orderId) {
      id
    }
  }
`;
const GET_CLOSED_ORDERS_ON_YEAR_PER_MONTH = gql`
  query generateArrayClosedOrdersOnYearQuery {
    generateArrayClosedOrdersOnYear {
      closedOrdersArray
    }
  }
`;

const IMPORT_ORDER_REPORTS_EXCEL_FILE = gql`
  mutation onImportOrderReportsExcelFile(
    $companyData: String!
    $serviceData: String!
    $locationData: String!
    $technicianData: Int!
  ) {
    importOrderReportsExcelFile(
      companyData: $companyData
      serviceData: $serviceData
      locationData: $locationData
      technicianData: $technicianData
    )
  }
`;

export const order = {
  query: {
    GET_CLOSED_ORDERS_ON_YEAR_PER_MONTH,
    GET_CLOSED_ORDERS_ON_MONTH,
    GET_USER_ORDER_ACTIVITY_DASHBOARD,
    GET_ORDERS_FOR_DASHBOARD,
    GET_ALL_ORDERS_BY_CUSTOMER,
    GET_ALL_ORDERS_BY_LOCATION,
    GET_CLOSED_ORDERS,
    GET_ALL_ORDERS,
    GET_ORDER_BY_ID,
  },
  mutation: {
    IMPORT_ORDER_REPORTS_EXCEL_FILE,
    CREATE_ORDER,
    APPROVE_AND_ALLOCATE_ORDER,
    WORK_ON_ORDER,
    CREATE_ORDER_CLOSE_OR_POSTPONE_NCR,
    CLOSE_ORDER,
    ORDER_TO_PAID,
    ORDER_TO_CANCEL,
  },
  subscription: {},
};
