import { useRef, useState, useEffect } from "react";
import type { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import UserIcon from "../../icons/User";
import useSettings from "../../hooks/useSettings";
import { THEMES } from "../../constants";
import { useQuery } from "@apollo/client";
import requests from "../../requests";
import { useTranslation } from "react-i18next";

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
});

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const { settings, saveSettings } = useSettings();
  const navigate = useNavigate();
  const { t } = useTranslation("profile");
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState(getValues(settings));
  const { data: userData } = useQuery(requests.user.query.ME_QUERY, {
    fetchPolicy: "network-only",
  });
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value,
    });
    saveSettings({
      ...values,
      [field]: value,
    });
  };
  useEffect(() => {
    saveSettings(values);
  }, [saveSettings, values]);

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        {userData && userData.me.profile ? (
          <Avatar
            src={user.avatar}
            sx={{
              height: 32,
              width: 32,
            }}
          >
            {userData.me.profile.firstName
              ? userData.me.profile.firstName[0] +
                (userData.me.profile.lastName === ""
                  ? ""
                  : userData.me.profile.lastName[0])
              : ""}
          </Avatar>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        getContentAnchorEl={null}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.name}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {user.me ? user.me.role.name : ""}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            label={t("Table Theme")}
            name="theme"
            onChange={(event): void =>
              handleChange("theme", event.target.value)
            }
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option key={theme} value={theme}>
                {theme
                  .split("_")
                  .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                  .join(" ")}
              </option>
            ))}
          </TextField>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem component={RouterLink} to="/dashboard/profile">
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t("Profile ")}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            {t("Table Logout")}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
