import { gql } from "@apollo/client";

// GET THE TABLE LIST LOCATION

const GET_ALL_LOCATIONS = gql`
    query getLocationsQuery($regionData: String!, $locationData: String!, $page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!){
        allLocations(regionData: $regionData, locationData: $locationData, page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit){ 
            locations{
                id
                name
                address{
                    region{
                        region
                    }
                    city{
                        city
                    }
                    streetName
                    streetNo
                    address
                }
                contacts{
                    firstName
                    lastName
                    contactPhone{
                        phone
                    }
                }
            }
            count
        }
    }
`;

// GET ALL THE LOCATIONS

const SHOW_LOCATIONS = gql`
    query GetLocationsQuery {
        getLocations {
            id
            name
            address {
                region {
                    id
                    region
                }
                city {
                    id 
                    city
                }
            }
            customer{
                id
            }
        }
    }
`;

export const location = {
    query: {
        SHOW_LOCATIONS,
        GET_ALL_LOCATIONS,
    },
    mutation: {
    },
    subscription: {
    }
}