/* eslint-disable */
import { gql } from "@apollo/client";

const SEARCH_TEMPORARY_INVENTORY = gql`
  query onTemporaryInventorySearch(
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    temporaryInventories(
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      temporaryInventories {
        id
        name
        createdAt
      }
      count
    }
  }
`;

const GET_TEMPORARY_INVENTORY_BY_ID = gql`
  query GetTemporaryInventoryById($id: String!) {
    getTemporaryInventoryById(id: $id) {
      name
      inventoryId
      company {
        name
        inventoryId
      }
    }
  }
`;
const GET_ALL_TEMPORARY_INVENTORIES = gql`
  query GetAllTemporaryInventories {
    getAllTemporaryInventories {
      id
      name
      inventoryId
      company {
        name
        inventoryId
      }
    }
  }
`;
const GET_UNUSED = gql`
  query getUnusedTemporaryInventories {
    getUnusedTemporaryInventories {
      id
      name
      inventoryId
      company {
        name
        inventoryId
      }
    }
  }
`;

const RETURN_TO_INVENTORY = gql`
  mutation returnToInventoryMutation(
    $inventoryId: String!
    $materialId: String!
    $ammount: Float!
    $totalAmmount: Float!
    $companyInventoryId: String!
  ) {
    returnTIMaterialToInventory(
      inventoryId: $inventoryId
      materialId: $materialId
      ammount: $ammount
      totalAmmount: $totalAmmount
      companyInventoryId: $companyInventoryId
    ) {
      id
    }
  }
`;
const MOVE_TO_INVENTORY = gql`
  mutation moveToInventoryMutation(
    $inventoryId: String!
    $materialId: String!
    $ammount: Float!
    $totalAmmount: Float!
    $companyForTransfer: String!
  ) {
    moveMaterial(
      inventoryId: $inventoryId
      materialId: $materialId
      ammount: $ammount
      totalAmmount: $totalAmmount
      companyForTransfer: $companyForTransfer
    ) {
      id
    }
  }
`;
const RESET_INVENTORY = gql`
  mutation resetMaterialsTemporaryInventory($temporaryInventoryId: String!) {
    resetMaterialsTemporaryInventory(
      temporaryInventoryId: $temporaryInventoryId
    ) {
      id
    }
  }
`;
const UPDATE = gql`
  mutation updateTemporaryInventory($data: TemporaryInventoryUpdateInput!) {
    updateTemporaryInventory(data: $data) {
      id
    }
  }
`;
const CREATE = gql`
  mutation createTemporaryInventory($data: TemporaryInventoryCreateInput!) {
    createTemporaryInventory(data: $data) {
      id
      name
      company {
        id
        name
      }
    }
  }
`;
export const temporaryInventory = {
  query: {
    SEARCH_TEMPORARY_INVENTORY,
    GET_TEMPORARY_INVENTORY_BY_ID,
    GET_ALL_TEMPORARY_INVENTORIES,
    GET_UNUSED,
  },
  mutation: {
    CREATE,
    RESET_INVENTORY,
    RETURN_TO_INVENTORY,
    MOVE_TO_INVENTORY,
    UPDATE,
  },
  subscription: {},
};
