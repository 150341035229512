/* eslint-disable */
import { gql } from "@apollo/client";

const CREATE_SERVICE = gql`
mutation CreateServiceMutation($title: String!, $companyId: String!, $opName: String!, $opCode: String!, $opUM: String!, $opPrice: Float!) {
    createService(title: $title, companyId: $companyId, opName: $opName, opCode: $opCode, opUM: $opUM, opPrice: $opPrice) {
        title
        companyId
    }
}
`;

const SHOW_SERVICE = gql`
    query GetServicesQuery {
        getServices {
            id
            title
            company {
                id 
                name
            }
        }
    }
`;

const GET_SERVICE_BY_ID = gql`
    query GetServiceById ($id: String!) {
        getServiceById (id: $id){
            title
            company {
                id
                name
            }
            defaultServiceOperationId
        }
    }
`;
const GET_SERVICE_BY_COMPANY_ID = gql`
query GetServiceByCompanyId ($id: String!) {
    servicesByCompanyId (id: $id){
        id
        title
    }
}
`;

const UPDATE_SERVICE = gql`
mutation updateServiceMutation($id: String!, $title: String!, $companyId: String!) {
    updateService(id: $id, title: $title, companyId: $companyId) {
            id 
    }
}
`;

const SEARCH_SERVICE = gql`
    query onServiceSearch($page: Int!, $rowsPer: Int!, $search: String!, $order: String!, $crit: String!) {
        services(page: $page, rowsPer: $rowsPer, search: $search, order: $order, crit: $crit) {
            service{
                id
                title
                company{
                    name
                }
            }
            count
        }
    }
`;

const SET_DEFAULT_SERVICE_OPERATION = gql`
    mutation setDefaultServiceTypeOperation($serviceTypeId: String!, $serviceOperationId: String!) {
        setDefaultServiceTypeOperation(serviceTypeId: $serviceTypeId , serviceOperationId: $serviceOperationId) {
            completed
        }
    }
`;

export const serviceType = {
    query: {
        SHOW_SERVICE,
        SEARCH_SERVICE,
        GET_SERVICE_BY_ID,
        GET_SERVICE_BY_COMPANY_ID,
    },
    mutation: {
        CREATE_SERVICE,
        UPDATE_SERVICE,
        SET_DEFAULT_SERVICE_OPERATION
    },
    subscription: {
    }
}