import { gql } from '@apollo/client';

// GET ALL REGIONS

const SEARCH_REGIONS = gql`
    query onSearchRegions {
        regions {
            id
            region
        }
    }
`;

// GET ALL CITIES

const SEARCH_CITIES = gql`
    query searchCities($regionId:Int!){
        cities(regionId:$regionId){
          id
          city
          regionId
        }
    }
`;

export const general = {
    query: {
        SEARCH_REGIONS,
        SEARCH_CITIES,
    },
    mutation: {
    },
    subscription: {
    }
};
