import { useEffect } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@material-ui/core";
import "./i18n";
// import { ApolloProvider } from '@apollo/client';
import GlobalStyles from "./components/GlobalStyles";
import RTL from "./components/RTL";
import SplashScreen from "./components/SplashScreen";
import { gtmConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createTheme } from "./theme";
import { PaginationContextProvider } from "./contexts/PaginationContextProvider";

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <PaginationContextProvider>
          <SnackbarProvider dense maxSnack={3}>
            <GlobalStyles />
            {/* <SettingsDrawer /> */}
            {auth.isInitialized ? content : <SplashScreen />}
          </SnackbarProvider>
        </PaginationContextProvider>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
