import React, { FC, useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";

const renderIfHasPermission = (
  permissions: boolean[],
  permissionNeeded: number[]
) => {
  let hasPermission = false;
  permissionNeeded.forEach((e) => {
    // so we can use the id in the DB as reference for the valid permission (id starts at one and the index of a array at zero)
    if (permissions[e - 1]) hasPermission = true;
  });

  return hasPermission;
};

export const hasPermission = (
  roleValue: number,
  permissionNeeded: number[]
) => {
  if (!roleValue) return false;
  const permissions = permissionsToArray(roleValue);
  let hasPermission = true;
  permissionNeeded.forEach((i) => {
    if (!permissions[i - 1]) hasPermission = false;
  });

  return hasPermission;
};

export const permissionsToArray = (x: number): boolean[] =>
  x
    .toString(2)
    .split("")
    .map((item) => !!Number(item))
    .reverse();
interface PermissionWrapperProps {
  children: React.ReactNode;
  validPermissions: number[];
}

const PermissionWrapper: FC<PermissionWrapperProps> = ({
  children,
  validPermissions,
}) => {
  const [permissions, setPermissions] = useState<boolean[]>([]);
  const [test, setTest] = useState(0);
  const auth = useAuth();
  useEffect(() => {
    if (auth && auth.user && auth.user.me && test === 0) {
      if (auth.user.me.role.value) {
        setPermissions(permissionsToArray(auth.user.me.role.value));
        setTest(1);
      }
    }
  }, [auth, permissions, setPermissions, test]);

  return renderIfHasPermission(permissions, validPermissions) ? (
    <>{children}</>
  ) : (
    <></>
  );
};

export default PermissionWrapper;
