/* eslint-disable */
import { gql } from "@apollo/client";
//mutation
const CREATE_CLIENT = gql`
  mutation CreateCustomerMutation($data: CustomerCreateInput!) {
    createCustomer(data: $data) {
      id
    }
  }
`;
const CREATE_LOCATION = gql`
  mutation CreateLocationMutation($data: LocationCreateInput!) {
    createLocation(data: $data) {
      id
    }
  }
`;
const CREATE_CONTACT = gql`
  mutation CreateContactMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $locationId: String!
  ) {
    createContact(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      locationId: $locationId
    ) {
      id
    }
  }
`;
const CREATE_EQUIPMENT = gql`
  mutation CreateEquipmentMutation(
    $name: String!
    $warranty: DateTime!
    $locationId: String!
  ) {
    createEquipment(name: $name, warranty: $warranty, locationId: $locationId) {
      id
    }
  }
`;
//update
const UPDATE_CLIENT = gql`
  mutation UpdateCustomerMutation($data: CustomerUpdateInput!) {
    updatedCustomer(data: $data) {
      id
    }
  }
`;
const UPDATE_LOCATION = gql`
  mutation UpdatelocationMutation($data: LocationUpdateInput!) {
    updatedLocation(data: $data) {
      id
    }
  }
`;
const UPDATE_CONTACT = gql`
  mutation UpdateContactMutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $contactId: String!
  ) {
    updatedContact(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      contactId: $contactId
    ) {
      id
    }
  }
`;
const UPDATE_EQUIPMENT = gql`
  mutation UpdateEquipmentMutation(
    $name: String!
    $warranty: DateTime!
    $equipmentId: String!
  ) {
    updatedEquipment(
      name: $name
      warranty: $warranty
      equipmentId: $equipmentId
    ) {
      id
    }
  }
`;
const DELETE_EQUIPMENT = gql`
  mutation deleteEquipment($id: String!) {
    deleteEquipment(id: $id) {
      id
    }
  }
`;
//query
const GET_CLIENTS = gql`
  query getCustomersQuery(
    $argCompany: String!
    $argSubscription: Boolean
    $argClientType: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    getCustomers(
      argCompany: $argCompany
      argSubscription: $argSubscription
      argClientType: $argClientType
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      customers {
        id
        type
        lead
        subscription
        company {
          name
        }
        phoneNumbers {
          phone
        }
        business {
          name
        }
        residential {
          firstName
          lastName
        }
        mainAddress {
          city {
            city
          }
          region {
            region
          }
          address
          streetName
          streetNo
        }
        business {
          name
        }
      }
      count
    }
  }
`;
const GET_LEAD_CLIENTS = gql`
  query getLeadCustomersQuery(
    $argCompany: String!
    $argClientType: String!
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    leadCustomers(
      argCompany: $argCompany
      argClientType: $argClientType
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      customers {
        id
        type
        subscription
        company {
          name
        }
        phoneNumbers {
          phone
        }
        business {
          name
        }
        residential {
          firstName
          lastName
        }
        mainAddress {
          city {
            city
          }
          region {
            region
          }
          address
          streetName
          streetNo
        }
        business {
          name
        }
      }
      count
    }
  }
`;
const GET_ALL_CUSTOMERS = gql`
  query getAllCustomersQuery {
    allCustomers {
      id
      type
      subscription
      phoneNumbers {
        phone
      }
      business {
        name
      }
      residential {
        firstName
        lastName
      }
      mainAddress {
        city {
          city
        }
        region {
          region
        }
        address
        streetName
        streetNo
      }
      business {
        name
      }
    }
  }
`;
const GET_CUSTOMERS_BY_COMPANY_ID = gql`
  query getCustomersByCompanyId($id: String) {
    customersByCompanyId(id: $id) {
      id
      type
      business {
        name
      }
      residential {
        firstName
        lastName
      }
    }
  }
`;
const GET_CLIENT_BY_ID = gql`
  query getClientById($id: String!) {
    customerById(id: $id) {
      id
      type
      accountId
      lead
      subscription
      fullName
      phoneNumbers {
        phone
      }
      mainAddress {
        city {
          id
          city
        }
        region {
          id
          region
        }
        streetName
        streetNo
        address
      }
      business {
        name
        cui
        nrc
        bankAccount
        bank
      }
      residential {
        firstName
        lastName
        email
        ci {
          cnp
          idSeries
          idNo
        }
      }
    }
  }
`;

const GET_LOCATIONS_BY_CUSTOMER_ID = gql`
  query getLocationsById(
    $id: String
    $page: Int!
    $rowsPer: Int!
    $search: String!
    $order: String!
    $crit: String!
  ) {
    locationsById(
      id: $id
      page: $page
      rowsPer: $rowsPer
      search: $search
      order: $order
      crit: $crit
    ) {
      locations {
        id
        name
        address {
          region {
            id
            region
          }
          city {
            id
            city
          }
          streetName
          streetNo
          address
        }
        contacts {
          firstName
          lastName
          email
          contactPhone {
            phone
          }
        }
      }
      count
    }
  }
`;
const GET_ALL_LOCATIONS_BY_CUSTOMER_ID = gql`
  query getAllLocationsById($id: String) {
    allLocationsById(id: $id) {
      id
      name
      address {
        region {
          id
          region
        }
        city {
          id
          city
        }
        streetName
        streetNo
        address
      }
      contacts {
        firstName
        lastName
        email
        contactPhone {
          phone
        }
      }
    }
  }
`;

const GET_LOCATION_BY_ID = gql`
  query getLocationsById($id: String) {
    locationById(id: $id) {
      id
      name
      address {
        region {
          id
          region
        }
        city {
          id
          city
        }
        streetName
        streetNo
        address
      }
      customer {
        type
        subscription
        phoneNumbers {
          phone
        }
        mainAddress {
          region {
            region
          }
          city {
            city
          }
          streetName
          streetNo
          address
        }
        business {
          name
          cui
          nrc
          bankAccount
          bank
        }
        residential {
          firstName
          lastName
          email
          ci {
            cnp
            idSeries
            idNo
          }
        }
      }
    }
  }
`;

const GET_CONTACTS_BY_ID = gql`
  query getContactsById($id: String) {
    contactsById(id: $id) {
      id
      firstName
      lastName
      email
      contactPhone {
        phone
      }
    }
  }
`;

const GET_CONTACT_BY_ID = gql`
  query getContactById($id: String) {
    contactById(id: $id) {
      id
      firstName
      lastName
      email
      contactPhone {
        phone
      }
    }
  }
`;

const GET_EQUIPMENTS_BY_ID = gql`
  query getEquipmentsById($id: String) {
    equipmentsById(id: $id) {
      id
      name
      warranty
    }
  }
`;
const GET_EQUIPMENT_BY_ID = gql`
  query getEquipmentById($id: String) {
    equipmentById(id: $id) {
      id
      name
      warranty
    }
  }
`;

// TRANSFORM A PROSPECT IN A CLIENT

const UPDATE_PROSPECT_TO_CLIENT = gql`
  mutation onUpdateProspectToClient($id: String!, $lead: Boolean!) {
    updateProspectStatusToClient(id: $id, lead: $lead) {
      lead
    }
  }
`;

export const client = {
  query: {
    GET_CLIENTS,
    GET_LEAD_CLIENTS,
    GET_ALL_CUSTOMERS,
    GET_CUSTOMERS_BY_COMPANY_ID,
    GET_CLIENT_BY_ID,
    GET_LOCATIONS_BY_CUSTOMER_ID,
    GET_LOCATION_BY_ID,
    GET_CONTACTS_BY_ID,
    GET_CONTACT_BY_ID,
    GET_EQUIPMENTS_BY_ID,
    GET_EQUIPMENT_BY_ID,
    GET_ALL_LOCATIONS_BY_CUSTOMER_ID,
  },
  mutation: {
    UPDATE_PROSPECT_TO_CLIENT,
    CREATE_CLIENT,
    CREATE_LOCATION,
    CREATE_CONTACT,
    CREATE_EQUIPMENT,
    UPDATE_CLIENT,
    UPDATE_LOCATION,
    UPDATE_CONTACT,
    UPDATE_EQUIPMENT,
    DELETE_EQUIPMENT,
  },
  subscription: {},
};
